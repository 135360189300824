import React from 'react'
import {
  Container,
  CommonBanner,
  Section,
  Seo,
} from '@components'
import { PageLayout } from '../../components/site/layout/page-layout'
import { NewsPanel } from '../../components/site/news'

const NewsPage = ({ location }) => {
  return (
    <PageLayout pathname={location.pathname}>

      <CommonBanner title="Approov New & Noteworthy" />

      <Section>
        <Container className="container-sm">
          <NewsPanel className="py-16" />
        </Container>
      </Section>
    </PageLayout>
  )
}

export default NewsPage
